import { ICancelarPedidoCredito } from "../interfaces/request/clientes/ICancelarPedidoCredito";
import { IConfirmarCartao } from "../interfaces/request/clientes/IConfirmarCartao";
import { ICriarPedidoCredito, IEditarPedidoCredito } from "../interfaces/request/clientes/ICriarPedidoCredito";
import { ICriarMovimentoRequest } from "../interfaces/request/credenciados/ICriarMovimentoRequest";
import { IMovimentoRequest } from "../interfaces/request/credenciados/IMovimento";
import { ICartaoResponse, MovimentoExtratoFuncionarioDictionary } from "../interfaces/responses/clientes/ICartaoResponse";
import { IRelatorioCreditoFuncionarioResponse } from "../interfaces/responses/clientes/IRelatorioCreditoFuncionarioResponse";
import { IFuncionarioResponse } from "../interfaces/responses/clientes/IFuncionarioResponse";
import { IClienteRelatorioMovimentoResponse } from "../interfaces/responses/clientes/IClienteRelatorioMovimentoResponse";
import { IPedidoCreditoResponse } from "../interfaces/responses/clientes/IPedidoCreditoResponse.1";
import { IPedidoCreditoDetalheResponse } from "../interfaces/responses/clientes/IPedidoCreditoDetalheResponse";
import { IApiResult } from "../interfaces/responses/common/IApiResult";
import { IMovimento } from "../interfaces/responses/credenciados/IMovimento";
import api from "./Api";
import { IClienteLoteCartaoResponse } from "../interfaces/responses/clientes/ILoteCartaoResponse";
import { IResetarSenhaCartao, ISolicitarSegundaViaCartao } from "../interfaces/request/clientes/IResetarSenhaCartao";
import { IClienteCredenciado } from "../interfaces/responses/clientes/IClienteCredenciado";
import { IVincularCredenciadoCliente } from "../interfaces/request/clientes/IVincularCredenciadoCliente";
import { IValidarArquivoPedidoResponse } from "../interfaces/responses/clientes/IValidarArquivoPedidoResponse";
import ICriarFuncionario from "../interfaces/request/clientes/ICadastrarFuncionario";

class ClienteService {
    async obterCartoes(idCliente: string, cpf: string, numeroCartao: string, desligado: boolean = false): Promise<IApiResult<ICartaoResponse[]>> {
        let { data: result } = await api.get<IApiResult<ICartaoResponse[]>>(`cliente/obter-cartoes?idCliente=${idCliente}&cpf=${cpf}&numeroCartao=${numeroCartao}&desligado=${desligado}`);

        return result;
    }

    async obterPedidosCredito(idCliente: string, codigo: string, status: string, dataDisponibilizacao: string): Promise<IApiResult<IPedidoCreditoResponse[]>> {
        
        let { data: result } = await api.get<IApiResult<IPedidoCreditoResponse[]>>(`cliente/obter-pedidos-credito`, {
            params: {idCliente: idCliente, codigo: codigo, status: status, dataCredito: dataDisponibilizacao}
        });

        return result;
    }
    
    async obterPedidoCreditoDetalhes(idCliente: string, idPedidoCredito: string): Promise<IApiResult<IPedidoCreditoDetalheResponse[]>> {
        let { data: result } = await api.get<IApiResult<IPedidoCreditoDetalheResponse[]>>(`cliente/obter-pedidos-credito-detalhe?idCliente=${idCliente}&idPedidoCredito=${idPedidoCredito}`);

        return result;
    }

    async obterLotesCartao(/*dataInicio: string, dataFim: string, */codigo: number = 0): Promise<IApiResult<IClienteLoteCartaoResponse>> {
        // let { data: result } = await api.get<IApiResult<ICartaoResponse[]>>(`cliente/obter-lotes?dataInicial=${dataInicio}&dataFinal=${dataFim}&codigo=${codigo == 0 ? null : codigo}`);
        let { data: result } = await api.get<IApiResult<IClienteLoteCartaoResponse>>(`cliente/obter-lotes?codigo=${codigo == 0 ? null : codigo}`);
        return result;
    }

    async obterCredenciadosPorCliente(idCliente: string): Promise<IApiResult<IClienteCredenciado[]>> {
        let { data: result } = await api.get<IApiResult<IClienteCredenciado[]>>(`cliente/obter-credenciados/${idCliente}`);
        return result;
    }

    async obterCredenciados(): Promise<IApiResult<IClienteCredenciado[]>> {
        let { data: result } = await api.get<IApiResult<IClienteCredenciado[]>>(`cliente/obter-credenciados`);
        return result;
    }

    async obterCartoesSemLote(): Promise<IApiResult<ICartaoResponse[]>> {
        let { data: result } = await api.get<IApiResult<ICartaoResponse[]>>(`cliente/obter-cartoes-sem-lote`);

        return result;
    }

    async obterCartoesPorLote(idLoteCartao: string): Promise<IApiResult<ICartaoResponse[]>> {
        let { data: result } = await api.get<IApiResult<ICartaoResponse[]>>(`cliente/obter-cartoes-por-lote?idLoteCartao=${idLoteCartao}`);

        return result;
    }

    async relatorioUtilizacaoPorPeriodo(dataInicio: string, dataFim: string): Promise<IApiResult<IClienteRelatorioMovimentoResponse>> {
        let { data: result } = await api.get<IApiResult<IClienteRelatorioMovimentoResponse>>(`cliente/relatorio-utilizacao-por-periodo?dataInicial=${dataInicio}&dataFinal=${dataFim}`);

        return result;
    }

    async relatorioRecargaPorFuncionario(cpf: string, dataInicio: string, dataFim: string): Promise<IApiResult<IRelatorioCreditoFuncionarioResponse>> {
        let { data: result } = await api.get<IApiResult<IRelatorioCreditoFuncionarioResponse>>('cliente/obter-creditos',{
            params: {cpf: cpf, dataInicial: dataInicio, dataFinal: dataFim}
        });

        return result;
    }

    async obterExtrato(cpf: string): Promise<IApiResult<MovimentoExtratoFuncionarioDictionary>> {
        let { data: result } = await api.get<IApiResult<MovimentoExtratoFuncionarioDictionary>>('cliente/obter-todos-movimentos',{
            params: {cpf: cpf}
        });

        return result;
    }

    async criarPedidoCredito(idCliente: string, criarPedidoCredito: ICriarPedidoCredito): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`cliente/criar-pedido-credito/${idCliente}`, criarPedidoCredito);

        return result;
    }

    async criarLoteCartao(): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`cliente/criar-lote-cartao/`);

        return result;
    }

    async criarFuncionario(funcionario: ICriarFuncionario): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`cliente/criar-funcionario`, funcionario);

        return result;
    }

    async importarArquivoPedido(idGrupoEmpresarial: string, arquivo: FormData): Promise<IApiResult<any>> {
        let { data: result } = await api.post<IApiResult<any>>(`cliente/importar-pedido-credito/${idGrupoEmpresarial}`, arquivo);

        return result;
    }

    async editarPedidoCredito(idCliente: string, editarPedidoCredito: IEditarPedidoCredito): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/editar-pedido-credito/${idCliente}/${editarPedidoCredito.id}`, editarPedidoCredito);

        return result;
    }

    async validarArquivoPedido(arquivo: FormData): Promise<IApiResult<IValidarArquivoPedidoResponse>> {
        let { data: result } = await api.post<IApiResult<IValidarArquivoPedidoResponse>>(`cliente/validar/arquivo-pedido`, arquivo);

        return result;
    }

    async confirmarCartao(confirmarCartao: IConfirmarCartao): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/confirmar-cartao/${confirmarCartao.idCliente}`, confirmarCartao);

        return result;
    }

    async bloquearCartao(confirmarCartao: IConfirmarCartao): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/bloquear-cartao/${confirmarCartao.idCliente}`, confirmarCartao);

        return result;
    }

    async desbloquearCartao(confirmarCartao: IConfirmarCartao): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/desbloquear-cartao/${confirmarCartao.idCliente}`, confirmarCartao);

        return result;
    }

    async cancelarPedidoCredito(cancelarPedidoCredito: ICancelarPedidoCredito): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/cancelar-pedido-credito/${cancelarPedidoCredito.idCliente}`, cancelarPedidoCredito);

        return result;
    }

    async enviarParaProcessamentoPedidoCredito(cancelarPedidoCredito: ICancelarPedidoCredito): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/processar-pedido-credito/${cancelarPedidoCredito.idCliente}`, cancelarPedidoCredito);

        return result;
    }
    
    async resetarSenhaCartao(resetSenhaCartao: IResetarSenhaCartao): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/resetar-senha-cartao/${resetSenhaCartao.idCliente}`, resetSenhaCartao);

        return result;
    }

    async confirmarPedidosEmLote(): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/confirmar-todos-pedidos`, null);

        return result;
    }

    async solicitarSegundaViaCartao(segundaViaCartao: ISolicitarSegundaViaCartao) {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/segunda-via-cartao/${segundaViaCartao.idCliente}`, segundaViaCartao);

        return result;
    }

    async vincularCredenciados(idCliente: string, vincularCredenciadosRequest: IVincularCredenciadoCliente) {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/vincular-credenciados/${idCliente}`, vincularCredenciadosRequest);

        return result;
    }

    async desligarFuncionario(confirmarCartao: IConfirmarCartao): Promise<IApiResult<any>> {
        let { data: result } = await api.put<IApiResult<any>>(`cliente/desligar-funcionario/${confirmarCartao.idCliente}`, confirmarCartao);

        return result;
    }
}

export default new ClienteService();