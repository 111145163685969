import React, { useState } from "react"
import { Modal } from "react-bootstrap-v5";
import { ICartaoResponse } from "../../../interfaces/responses/clientes/ICartaoResponse";
import { IFuncionarioResponse } from "../../../interfaces/responses/clientes/IFuncionarioResponse";
import GridPadrao from "../../components/GridPadrao";
import ClienteService from "../../../services/ClienteService";
import { IConfirmarCartao } from "../../../interfaces/request/clientes/IConfirmarCartao";
import Swal from "sweetalert2";
import { IResetarSenhaCartao, ISolicitarSegundaViaCartao } from "../../../interfaces/request/clientes/IResetarSenhaCartao";
import { useHistory } from "react-router-dom";


interface FuncionarioModalDetalheProps {
    toggleModal: () => void,
    exibirModal: boolean,
    cartao: ICartaoResponse,
}

const FuncionarioModalDetalhe = ({ toggleModal, exibirModal, cartao }: FuncionarioModalDetalheProps) => {
    const [isLoading, setLoading] = useState<boolean>(false)
    const history = useHistory();
    const colunasCartao = [
        {
          name: 'Número',
          selector: (row: ICartaoResponse) => row['numero'],
          sortable: false,
          wrap: false,
        },
        {
            name: 'Senha Carta Berço',
            selector: (row: ICartaoResponse) => row['senhaCartaBerco'],
            sortable: false,
            wrap: false,
        },
        {
            name: 'Situação',
            selector: (row: ICartaoResponse) => row['status'],
            sortable: false,
            wrap: false,
        },
        {
            name: 'Saldo',
            selector: (row: ICartaoResponse) => row['saldoCartao'],
            sortable: false,
            wrap: false,
        },
        {
            name: 'Bloqueado',
            selector: (row: ICartaoResponse) => row['bloqueado'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: false,
        },
        {
            name: 'Bloqueio QrCode',
            selector: (row: ICartaoResponse) => row['bloqueioQrCode'] ? 'SIM' : 'NÃO',
            sortable: false,
            wrap: false,
        }
    ]

    const colunasFuncionario = [
        {
          name: 'Nome',
          selector: (row: IFuncionarioResponse) => row['nome'],
          sortable: false,
          wrap: false,
        },
        {
          name: 'CPF',
          selector: (row: IFuncionarioResponse) => row['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          sortable: false,
          wrap: false,
        }
    ]

    async function confirmarCartao(){
        try{
            setLoading(true)
            let result = await ClienteService.confirmarCartao({ 
                                                                idCartao: cartao.id,
                                                                idCliente: cartao.cliente.id,
                                                                idFuncionario: cartao.funcionario.id
                                                              } as IConfirmarCartao);
            Swal.fire({
                icon: 'success',
                title: 'Sucesso',
                text: 'Cartão Confirmado',
                showConfirmButton: true,
            })
            setLoading(false)
            toggleModal();
        }catch (error : any) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possível confirmar o cartão',
                text: error,
                showConfirmButton: true,
              })
              setLoading(false)
              toggleModal();
        }
    }

    async function bloquearCartao(){
        try{
            setLoading(true)
            let result = await ClienteService.bloquearCartao({ 
                                                                idCartao: cartao.id,
                                                                idCliente: cartao.cliente.id,
                                                                idFuncionario: cartao.funcionario.id
                                                              } as IConfirmarCartao);
            Swal.fire({
                icon: 'success',
                title: 'Sucesso',
                text: 'Cartão Bloqueado',
                showConfirmButton: true,
            })
            setLoading(false)
            toggleModal();
        }catch (error : any) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possível bloquear o cartão',
                text: error,
                showConfirmButton: true,
              })
              setLoading(false)
              toggleModal();
        }
    }

    async function desbloquearCartao(){
        try{
            setLoading(true)
            let result = await ClienteService.desbloquearCartao({ 
                                                                idCartao: cartao.id,
                                                                idCliente: cartao.cliente.id,
                                                                idFuncionario: cartao.funcionario.id
                                                              } as IConfirmarCartao);
            Swal.fire({
                icon: 'success',
                title: 'Sucesso',
                text: 'Cartão Desbloqueado',
                showConfirmButton: true,
            })
            setLoading(false)
            toggleModal();
        }catch (error : any) {
            Swal.fire({
                icon: 'error',
                title: 'Não foi possível desbloquear o cartão',
                text: error,
                showConfirmButton: true,
              })
              setLoading(false)
              toggleModal();
        }
    }

    async function resetarSenhaCartao(){
        Swal.fire({
            title: 'Redefinir senha do cartão?',
            html: `Deseja redefinir a senha do <strong class='text-danger'>cartão</strong> para a senha da <strong class='text-danger'>carta berço</strong>?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'SIM'
            
          }).then(async (confirmResult) => {
            try{
                if (confirmResult.isConfirmed) {
                    setLoading(true)
                    let result = await ClienteService.resetarSenhaCartao({ 
                                                                            idCartao: cartao.id,
                                                                            idCliente: cartao.cliente.id,
                                                                            idFuncionario: cartao.funcionario.id
                                                                          } as IResetarSenhaCartao);
                    Swal.fire({
                        icon: 'success',
                        title: 'Senha Redefinida com sucesso!',
                        text: 'A senha do cartão foi redefinida para a senha da carta berço',
                        showConfirmButton: true,
                    })
                    setLoading(false);
                    toggleModal();
                }
            }catch (error : any) {
                  Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possível redefinir a senha do cartão`
                    );
                    setLoading(false);
                    toggleModal();
            }
        })
    }

    async function solicitarSegundaViaCartao(){
        
        Swal.fire({
            title: 'Solicitar segunda via do cartão?',
            html: `Deseja solicitar a segunda via do <strong class='text-danger'>cartão</strong>?
            <br/>Essa operação irá alterar o <strong class='text-danger'>status do cartão</strong>, 
            <strong class='text-danger'>bloquear os pagamentos</strong> e 
            <strong class='text-danger'>resetar a senha do cartão</strong>, tem certeza?`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'NÃO',
            confirmButtonColor: '#28a745',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'SIM'
            
          }).then(async (confirmResult) => {
            try{
                if (confirmResult.isConfirmed) {
                    setLoading(true)

                    let result = await ClienteService.solicitarSegundaViaCartao({ 
                                                                            idCartao: cartao.id,
                                                                            idCliente: cartao.cliente.id,
                                                                            idFuncionario: cartao.funcionario.id
                                                                          } as ISolicitarSegundaViaCartao);
                    Swal.fire({
                        icon: 'success',
                        title: 'Segunda via solicitada com sucesso!',
                        html: `Você ainda precisa emitir um novo lote para esse cartão.
                        Clique no botão "+ Novo Lote".<br/>
                        <strong class='text-danger'>Se essa etapa não for realizada, o cartão não sera emitido.</strong><br/>`,
                        showConfirmButton: true,
                        confirmButtonColor: '#28a745',
                        confirmButtonText: 'ENTENDI'
                    })

                    history.push('/cliente/lotes-cartao')
                }
            }catch (error : any) {
                  Swal.fire(
                    Array.isArray(error) ? error[0] : `Não foi possível redefinir a senha do cartão`
                    );
                    setLoading(false);
                    toggleModal();
            }
        })
    }

    return <>
        <Modal size='lg' centered={true} show={exibirModal} onHide={toggleModal}>
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title titulo-modal">Funcionário - Cartão</h5>
                    <div onClick={() => toggleModal()} className="btn btn-icon btn-sm btn-active-light-primary ms-2">
                        <i className="fas fa-times" style={{ color: '#5E6397' }}></i>
                    </div>
                </div>
                <div className="modal-body">
                    <h4>Funcionário</h4>
                    <div>
                        {cartao ? 
                            (<GridPadrao paginar={false} colunas={colunasFuncionario} tipo='Funcionário' itens={[cartao.funcionario]} />) : (<></>)
                        }
                    </div>
                    <hr />
                    <h4>Cartão</h4>
                    <div>
                        {cartao ? 
                            (<GridPadrao paginar={false} colunas={colunasCartao} tipo='Cartão' itens={[cartao]} />) : (<></>)
                        }
                    </div>
                    
                </div>
                <div className="modal-footer" style={{ margin: '0 auto' }}>
                    <button  onClick={() => toggleModal()} type="button" className="btn btn-secondary me-5"> VOLTAR </button>
                    {
                        cartao ? 
                        (
                            cartao.status === 'AguardandoConfirmacao' ?
                            (
                                <button disabled={isLoading} onClick={() => confirmarCartao()} type="button" className="btn btn-success ms-5">
                                    {isLoading ? (
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                    ):(
                                        <></>
                                    )
                                    }
                                    CONFIRMAR CARTÃO
                                </button>
                            ) 
                            :
                            (<> 
                                {
                                    cartao ? (
                                        cartao.bloqueado === true && cartao.bloqueioQrCode === true ?
                                    (
                                        <button disabled={isLoading} onClick={() => desbloquearCartao()} type="button" className="btn btn-success ms-5">
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            ):(
                                                <></>
                                            )
                                            }
                                            DESBLOQUEAR CARTÃO
                                        </button>
                                    ) 
                                    :
                                    (
                                        <button disabled={isLoading} onClick={() => bloquearCartao()} type="button" className="btn btn-danger ms-5">
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            ):(
                                                <></>
                                            )
                                            }
                                            BLOQUEAR CARTÃO
                                        </button>
                                    )
                                    ) : (<></>)
                                    
                                }
                                    <button disabled={isLoading} onClick={() => resetarSenhaCartao()} type="button" className="btn btn-warning ms-5">
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                            ):(
                                                <></>
                                            )
                                            }
                                            REDEFINIR SENHA
                                    </button>
                                </>
                            )
                        ) :
                        (<></>)
                    }
                    <button type="button" className="btn btn-primary ms-5" disabled={isLoading} onClick={() => solicitarSegundaViaCartao()}>SOLICITAR 2ª VIA</button>
                </div>
            </div>
        </Modal>
    </>
}

export default FuncionarioModalDetalhe;