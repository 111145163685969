import { useFormik } from "formik";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap-v5";
import Swal from "sweetalert2";
import { useAuth } from "../../../contexts/AuthProvider";
import { ICartaoResponse } from "../../../interfaces/responses/clientes/ICartaoResponse";
import ClienteService from "../../../services/ClienteService";
import ComboBox, { ComboboxItem } from "../../components/ComboBox";
import GridPadrao from "../../components/GridPadrao";
import HeaderContent from "../../components/HeaderContent";
import PainelFiltro from "../../components/PainelFiltro";
import FuncionarioModalCriar from "./FuncionarioModalCriar";
import FuncionarioModalDetalhe from "./FuncionarioModalDetalhe";
import clsx from "clsx";
import InputCPF from "../../components/InputCPF";
import InputCartao from "../../components/InputCartao";
import * as Yup from 'yup';
import FormInputSelect, { FormInputMultiSelectItems } from "../../components/FormInputSelect";
import { Link } from "react-router-dom";
import { IConfirmarCartao } from "../../../interfaces/request/clientes/IConfirmarCartao";
                  
export function Funcionarios() {
  const [exibirModalCriar, setExibirModalCriar] = useState<boolean>(false)
  const [itens, setItens] = useState<FormInputMultiSelectItems[]>([])
  const [loadingInicial, setLoadingInicial] = useState<boolean>(true)
  const { clientes } = useAuth();
  const [cartoes, setCartoes] = useState<ICartaoResponse[]>([])

  const [cartaoSelecionado, setCartao] = useState<ICartaoResponse>({} as ICartaoResponse)
  const [exibirModalDetalhe, setExibirModalDetalhe] = useState<boolean>(false)

  useEffect(() => {
    carregarComboBox();
  }, [])
  
  function toggleModalCriar(carregarDados?: boolean): void {
    setExibirModalCriar(!exibirModalCriar);
  }

  function toggleModalDetalhe(): void {
    if (exibirModalDetalhe) {
      formikFiltro.submitForm()
    }
    setExibirModalDetalhe(!exibirModalDetalhe);
  }

  async function carregarComboBox(){
    clientes?.map((p) => itens.push({ value: p.id, label: p.descricao }));
    setItens(itens);
    setLoadingInicial(false);
  }

  const valoresIniciaisFiltro = {
    idCliente: '',
    cpf: '',
    numeroCartao: '',
    desligado: false
  } as any;
  
  const filtroValidacoes = Yup.object().shape({
    cpf: Yup.string().min(11, "CPF deve possuir exatamente 11 caracteres").max(11, "CPF deve possuir exatamente 11 caracteres"),
    numeroCartao: Yup.string().min(18, "Número cartão deve possuir exatamente 18 caracteres").max(18, "Número cartão deve possuir exatamente 18 caracteres")
  });

  const formikFiltro = useFormik({
    initialValues: valoresIniciaisFiltro,
    validationSchema: filtroValidacoes,
    onSubmit: async ({idCliente, cpf, numeroCartao, desligado}) => {
      try {
        setLoadingInicial(true)
        
        const { data } = await ClienteService.obterCartoes(idCliente, cpf, numeroCartao, desligado)
        
        setCartoes(data)
      
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Não foi possivel carregar os Funcionários',
          text: 'Atualize a pagina, caso o erro continue entre em contato com o administrador.',
          showConfirmButton: true,
        })
      } finally {
        setLoadingInicial(false)
      } 
    }
  });
  
  const colunas = [
    {
      name: 'Nome',
      selector: (row: ICartaoResponse) => row.funcionario['nome'],
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'CPF',
      selector: (row: ICartaoResponse) => row.funcionario['cpf'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Filial Nome',
      selector: (row: ICartaoResponse) => row.cliente['nome'],
      sortable: true,
      wrap: true,
      grow: 2
    },
    {
      name: 'Filial CNPJ',
      selector: (row: ICartaoResponse) => row.cliente['cnpj'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5"),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Número Cartão',
      selector: (row: ICartaoResponse) => row['numero'],
      sortable: true,
      wrap: true,
    },
    {
      name: 'Lote',
      selector: (row: ICartaoResponse) => row['lote'] ? row['lote'] : 'SEM LOTE',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Ações',
      cell: (cartao: ICartaoResponse) => {
        return (
         <>
            <div className="p-1">
              <button
                  title="Detalhes"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => {
                    setCartao(cartao);
                    setExibirModalDetalhe(true);
                  }}
              >
                  <i style={{fontSize: 24}} className="bi bi-credit-card-fill"></i>
              </button>
            </div>
            <div className="p-1">
              <Link 
                to={`/cliente/funcionarios/extrato/${cartao.funcionario.cpf}`} 
                className='btn btn-sm btn-icon btn-secondary me-1'
                title="Extrato">
                <i style={{fontSize: 24}} className="bi bi-receipt"></i>
              </Link>
              </div>
            <div className="p-1">
            <button
                  title="Desligar Funcionário"
                  className='btn btn-sm btn-icon btn-secondary me-1'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => desligarLigarFuncionario(cartao)}
              >

                {
                  cartao.funcionario.desligado ? 
                  <i style={{fontSize: 24}} className="bi bi-person-fill-add"></i> 
                    :
                  <i style={{fontSize: 24}} className="bi bi-person-fill-dash"></i>
                }
              </button>
            </div>
         </>
        )
      },
    },
  ]

  function desligarLigarFuncionario(cartao: ICartaoResponse){
    const msgDesligar =`Você não terá mais acesso à visualização dos dados do funcionário ${cartao.funcionario.nome}.`;
    const msgLigar = `Funcionário ${cartao.funcionario.nome} ficará acessível novamente.` 
    Swal.fire({
      title: `Deseja ${cartao.funcionario.desligado ? 'ligar' : 'desligar' } o funcionário?`,
      text: cartao.funcionario.desligado ? msgLigar : msgDesligar,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'CANCELAR',
      confirmButtonText: `${cartao.funcionario.desligado ? 'LIGAR' : 'DESLIGAR'} FUNCIONÁRIO`,
      reverseButtons: true,
      customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger'
      }
      
    }).then(async (result) => {
      if (result.isConfirmed) {
          try{
            setLoadingInicial(true);
            await ClienteService.desligarFuncionario({
              idCartao: cartao.id,
              idCliente: cartao.cliente.id,
              idFuncionario: cartao.funcionario.id
            } as IConfirmarCartao)

            Swal.fire({
              icon: 'success',
              text: `Alteração realizada com sucesso`,
              showConfirmButton: true,
          })

          formikFiltro.submitForm();
          }
          catch(error){
            Swal.fire(
              Array.isArray(error) ? error[0] : `Não foi possivel atualizar o funcionário`
              );
          }
          finally{
            setLoadingInicial(false);
          }
      }
  })
  }
  
  return (
    <>
      <FuncionarioModalCriar
        toggleModal={toggleModalCriar}
        exibirModal={exibirModalCriar}
      />

      
      <FuncionarioModalDetalhe 
        exibirModal={exibirModalDetalhe}
        toggleModal={toggleModalDetalhe}
        cartao={cartaoSelecionado}
      />
      
      <HeaderContent title={'Funcionários'} >
        <button 
            onClick={() => {
            setExibirModalCriar(true)
            }}  className='btn btn-sm btn-primary'>
          <i className="icon-lg text-white fas fa-plus "></i>
          <span style={{ verticalAlign: 'middle' }} > Novo Funcionario</span>
        </button>
      </HeaderContent>
        <PainelFiltro>
              {
                  <>
                    <FormInputSelect 
                        disabled={formikFiltro.isSubmitting} 
                        values={itens} 
                        formik={formikFiltro} 
                        className="col col-md-4" 
                        propName='idCliente' 
                        label='Cliente' />

                    <InputCPF 
                        formik={formikFiltro}
                        label="CPF"
                        name="cpf"
                        className="col col-md-2"
                        placeholder="Busque por CPF"
                        required={false} />

                    <div className="col col-md-3">
                      <label className='form-label fw-bold'>Cartão</label>
                      <InputCartao 
                        id="form-numeroCartao"
                        handlechange={async (e: ChangeEvent<HTMLInputElement>) => {
                          e.target.focus()
                          await formikFiltro.setFieldValue(e.target.name, e.target.value.replace(/[^\d]/g, ''));
                          await formikFiltro.setFieldTouched(e.target.name);
                          }}
                          value={formikFiltro.values.numeroCartao}
                          name="numeroCartao"
                          placeholder="Busque por Cartão"
                          className={clsx(
                            'form-control',
                            {
                                'is-invalid': formikFiltro.touched.numeroCartao && formikFiltro.errors.numeroCartao,
                            },
                            {
                                'is-valid': formikFiltro.touched.numeroCartao && !formikFiltro.errors.numeroCartao,
                            }
                        )}
                        />
                        {formikFiltro.touched.numeroCartao && formikFiltro.errors.numeroCartao && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                    <span role='alert'>{formikFiltro.errors.numeroCartao}</span>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="mb-10">
                    <div className="form-check form-switch form-check-custom form-check-solid mt-2  ">
                        <input 
                        className="form-check-input" 
                        type="checkbox" 
                        {
                          ...formikFiltro.getFieldProps('desligado')
                        }/>
                        <label className="form-check-label" htmlFor="desligado">
                          Apenas desligados
                        </label>
                    </div>
                  </div>
                  </>
              }
              {
                  <>
                      <button data-kt-menu-trigger='click'
                          data-kt-menu-placement='bottom-end'
                          data-kt-menu-flip='top-end'
                          className='btn btn-sm btn-flex btn-primary btn-active-primary fw-bolder me-3'
                          onClick={formikFiltro.submitForm}
                          disabled={formikFiltro.isSubmitting || !formikFiltro.isValid}>
                          <i className="icon-lg fa fa-filter"></i>
                          Filtrar
                      </button>
                  </>
              }
        </PainelFiltro>
      {loadingInicial ? (
          <Spinner animation='border' />
        ) : (
          <div>
               <div className="d-flex flex-row-reverse" style={{opacity: 0.5}}>
              <ul className="list-group list-group-horizontal mb-2">
                <li className="list-group-item"><i style={{fontSize: 20, marginRight: 4}} className="bi bi-credit-card-fill"></i>Detalhes Cartão</li>
                <li className="list-group-item"><i style={{fontSize: 20, marginRight: 4}} className="bi bi-receipt"></i>Extrato Completo</li>
                <li className="list-group-item"><i style={{fontSize: 20, marginRight: 4}} className="bi bi-person-fill-dash"></i>Desligar Funcionário</li>
                <li className="list-group-item"><i style={{fontSize: 20, marginRight: 4}} className="bi bi-person-fill-add"></i>Ligar Funcionário</li>
              </ul>
            </div>
            <GridPadrao colunas={colunas} tipo='Funcionários' itens={cartoes} />
          </div>
        )}
    
    </>
  )
}